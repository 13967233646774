$orange-color: #F7941D;
$brown-color: #523A20;
$green-color: #629C3B;

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  color: $brown-color;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

h1 {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: $orange-color;
  @media (min-width: 576px) {
    font-size: 42px;
  }
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 65px;
  }
}

h2 {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: $brown-color;
  @media (min-width: 576px) {
    font-size: 36px;
    line-height: 49px;
  }
}

.text-green {
  color: $green-color;
}

.btn {
  background: $orange-color;
  border-radius: 8px;
  min-width: 160px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 8px;

  &:hover {
    color: #FFFFFF;
  }
}

.section-01 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 90%, #FFFFFF 100%), url('../img/bg-01.jpg') no-repeat top left;
  background-size: cover;
  min-height: 600px;
  @media (min-width: 768px) {
    min-height: 700px;
  }
  @media (min-width: 992px) {
    min-height: 800px;
  }

  &__logo {
    margin-bottom: 70px;
  }
}

.section-03 {
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 16.41%, rgba(255, 255, 255, 0) 90%, #FFFFFF 100%), url('../img/bg-02.jpg') no-repeat top right;
  background-size: cover;
  @media (min-width: 992px) {
    min-height: 620px;
  }
}

.form-control {
  background: transparent;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid $brown-color;
  padding: 0 10px 10px 10px;
  height: auto;
  color: transparentize($brown-color, 0.2);
  font-size: 14px;
  border-radius: 0;

  &:focus {
    background: transparent;
    color: $brown-color;
    border-color: $brown-color;
  }
}

.form-group {
  position: relative;
}

.form-control::-webkit-input-placeholder {
  font-size: 14px;
  color: transparentize($brown-color, 0.2);
  font-family: Noto Sans, sans-serif;
}

.form-control::-moz-placeholder {
  font-size: 14px;
  color: transparentize($brown-color, 0.2);
  font-family: Noto Sans, sans-serif;
}

.form-control:-moz-placeholder {
  font-size: 14px;
  color: transparentize($brown-color, 0.2);
  font-family: Noto Sans, sans-serif;
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
  color: transparentize($brown-color, 0.2);
  font-family: Noto Sans, sans-serif;
}

.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
}

.form-control:focus::-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-ms-input-placeholder {
  opacity: 0;
}

.custom-control {
  padding-left: 30px;
}

.custom-control-label {
  font-size: 12px;
  line-height: 21px;
  display: block;

  &:before {
    position: absolute;
    top: 0;
    width: 21px;
    height: 21px;
    background-color: transparent;
    border: $brown-color solid 1px;
    border-radius: 0 !important;
    left: -30px;
  }

  &:after {
    background-size: unset;
    width: 15px;
    height: 15px;
    top: 3px;
    left: -27px;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23523A20' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $brown-color;
  border-color: $brown-color;
  background-color: transparent;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: unset;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: $brown-color;
  background-color: transparent;
  border-color: $brown-color;
}

span.error-label {
  position: absolute;
  left: 0;
  padding: 4px 12px 4px;
  line-height: 16px;
  background: #f2dedf;
  font-size: 11px;
  color: #f11a48;
  z-index: 1;
  top: calc(100% + 5px);
  margin: 5px 0 0 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 30px;
    border-style: solid;
    border-width: 8px 8px 8px 8px;
    border-color: transparent transparent #f2dfdf transparent;
  }
}

footer {
  background-color: $brown-color;
}

.footer-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.copyright {
  font-size: 14px;

  a {
    color: #ffffff;
  }
}
